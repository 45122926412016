// init base functions
$(window).on('load', function () {
    classOnBody($('main').attr('id'));
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
});
$(window).on('hashchange', function () {
    initGettersAndSetters();
});

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
}

// nav
if ($('.l-nav').length) {
    // vars
    var nav_scrollInit = 0;
    var nav_classActive = 'is-active';
    var nav_el = {
        body: $('body'),
        hamburguer: $('.c-hamburguer'),
        mask: $('.l-nav__maskMenu'),
        nav: $('.l-nav'),
        navContentHero: $('.l-nav__contentHero'),
        liWithOneLevelSubMenu: $('.liWithOneLevelSubMenu > a'),
        liWithTwoLevelsSubMenu: $('.liWithTwoLevelsSubMenu > a'),
        liWithoutSubMenu: $('.liWithoutSubMenu > a'),
        firstSubMenu: $('.firstSubMenu'),
        secondSubMenu: $('.secondSubMenu'),
        openSecondSubMenu: $('.openSecondSubMenu'),
    };

    // functions
    function showMask() {
        nav_el.mask.addClass(nav_classActive);
    }
    function hideMask() {
        nav_el.mask.removeClass(nav_classActive);
    }
    function overflowBlocked() {
        nav_el.body.addClass('u-overflowBlocked');
    }
    function overflowFree() {
        nav_el.body.removeClass('u-overflowBlocked');
    }
    function closeNavContent() {
        nav_el.navContentHero.removeClass(nav_classActive);
    }
    function openNavContent() {
        nav_el.navContentHero.addClass(nav_classActive);
    }
    function inactiveHamburguer() {
        nav_el.hamburguer.removeClass(nav_classActive);
    }
    function activeHamburguer() {
        nav_el.hamburguer.addClass(nav_classActive);
    }

    function closeAllMenuItens() {
        hideMask();
        overflowFree();
        closeNavContent();
        inactiveHamburguer();
    }
    function openAllMenuItens() {
        showMask();
        overflowBlocked();
        openNavContent();
        activeHamburguer()
    }

    // effect on scroll
    $(window).on('scroll', function(event) {
        var scrollBody = $(this).scrollTop();

        // scroll up to 99
        if (scrollBody > 99) {
            nav_el.nav.addClass('scrolled');
        } else {
            nav_el.nav.removeClass('scrolled');
        }

        // middle class
        if (scrollBody > 600) {
            nav_el.nav.addClass('hidden');
            nav_el.nav.addClass('scrolledMiddle');
        } else {
            nav_el.nav.removeClass('hidden');
            nav_el.nav.removeClass('scrolledMiddle');
        }

        // scroll up or down
        if (scrollBody < nav_scrollInit) {
            nav_el.nav.removeClass('hidden');
            nav_el.nav.addClass('scrolledUp');
            nav_el.nav.removeClass('scrolledDown');
        } else {
            nav_el.nav.removeClass('scrolledUp');
            nav_el.nav.addClass('scrolledDown');
        }

        // close menus on hidden nav
        if(nav_el.nav.hasClass('hidden')) {
            closeAllMenuItens();
        }

        // reference var
        nav_scrollInit = scrollBody;
    });
    $(window).on('load', function(event) {
        var scrollBody = $(this).scrollTop();
        if (scrollBody > 1) {
            nav_el.nav.addClass('scrolled');
        } else {
            nav_el.nav.removeClass('scrolled');
        }
    });

    // open/close/menus/submenus/hamburguer on clicks
    nav_el.hamburguer.on('click', function() {
        if ($(this).hasClass(nav_classActive)) {
            closeAllMenuItens();
        } else {
            openAllMenuItens();
        }
    });
    nav_el.mask.on('click', function() {
        closeAllMenuItens()
    });
    nav_el.liWithoutSubMenu.on('click', function() {
        closeAllMenuItens();
    });
}

// modules
if ($('#w_ieDetect').length) {
    function showMessageOnIE(warnLevel) {
        // warnLevel = 1 -> Remover aviso
        // warnLevel = 2 -> Recolher aviso
        // warnLevel = 3 -> Bloquear navegação

        var ieDetectEl = $('#w_ieDetect');
        var ieDetectEl__mask = $('#w_ieDetect__mask');
        var ieDetectEl__unlock = $('.w_ieDetect__unlockSite');

        if (is.ie()) {
            if(warnLevel === 1) {
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__unlock.on('click', function() {
                    ieDetectEl.remove();
                    ieDetectEl__mask.remove();
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if(warnLevel === 2) {
                //block overflow and show modal
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');

                // hide modal and mask
                ieDetectEl__unlock.on('click', function() {
                    $(this).remove();
                    ieDetectEl__mask.remove();
                    ieDetectEl.removeClass('is-visible');
                    ieDetectEl.addClass('is-fixed');
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if(warnLevel === 3) {
                $('nav').remove();
                $('header').remove();
                $('footer').remove();
                $('main').remove();
                ieDetectEl__unlock.remove();
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
            }
        } else {
            ieDetectEl.remove();
            ieDetectEl__mask.remove();
        }
    }
    $(window).on('load', showMessageOnIE(2));
}